<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1"><v-icon>mdi-cog-outline</v-icon>Tickets Recepcion</span>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-checkbox v-model="urgente" :label="`Urgente`"></v-checkbox>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-text-field v-model="search" label="Buscar" dense hide-details outlined></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="getTickets" small rounded>
              Actualizar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-radio-group v-model="estatusFiltro" row dense hide-details small class="ma-0">
              <v-radio color="orange" label="Abierto" :value="1"></v-radio>
              <v-radio color="blue" label="Asignado" :value="2"></v-radio>
              <v-radio color="red" label="Pendiente" :value="3"></v-radio>
              <v-radio color="grey" label="Revisión" :value="4"></v-radio>
              <v-radio color="green" label="Respondido" :value="5"></v-radio>
              <v-radio color="yellow" label="Cerrado" :value="6"></v-radio>
              <v-radio color="black" label="Rechazado" :value="7"></v-radio>
              <v-radio label="Todos" :value="8"></v-radio>
            </v-radio-group>
            <br>

            <v-data-table :headers="headers" :items="filterTickets" sort-by="calories" dense class="elevation-0" :search="search" :item-class="itemRowBackground">
              <template v-slot:item.estatus="{ item }">
                <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==1"> Abierto </v-chip>
                <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==2"> Asignado </v-chip>
                <v-chip small :color="getColor(item.estatus)" dark v-if="item.estatus==3"> Pendiente </v-chip>
                <v-chip small color="grey" dark v-if="item.estatus==4"> Revisión </v-chip>
                <v-chip small color="green" dark v-if="item.estatus==5"> Respondido </v-chip>
                <v-chip small color="yellow"  v-if="item.estatus==6"> Cerrado </v-chip>
                <v-chip small color="black" dark v-if="item.estatus==7"> Rechazado </v-chip>
              </template>

              <template v-slot:item.respuestas="{ item }">
                <v-btn color="success" small rounded @click="verMotivo(item)"> Ver </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="getTickets" small rounded class="my-2">
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Visualizar historial -->
    <verTicket @close="closeModal" :isOpen="dialogHistorial" :historial="historial" :ticket="ticket"></verTicket>
    
    <!-- Dialog de carga -->
    <carga                v-if="cargar"/>
  </v-container>
</template>

<script>
  import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');

  import {mapGetters, mapActions} from 'vuex'
  import verTicket              from '@/components/tickets/verTicket.vue'
  import carga                 from '@/components/alertas/carga.vue'

  export default {
    components:{
      verTicket,
      carga,
    },
    data: () => ({
      cargar : false,
      dialogHistorial:false,
      estatusFiltro: 8,
      search:'',
      ticket:'',
      urgente: false,
      tickets: [],
      historial: [],
      headers: [
        { text: 'Folio'            , value: 'idticket', sortable: false },
        { text: 'Fecha de creación', value: 'fecha_apertura', sortable: false },
        { text: 'Motivo'           , value: 'motivocorto' ,   sortable: false },
        { text: 'Estatus'          , value: 'estatus'         },
        { text: 'Recepcionista'    , value: 'recepcionista'   },
        { text: 'Area'             , value: 'area'   },
        { text: 'Encargado'        , value: 'nombre_jefe'   },
        { text: 'Respuestas'       , value: 'respuestas', sortable: false },
        ],
    }),

    created () {
      this.initialize()
    },

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      filterTickets () {
        if(this.tickets){
          let newtickets = this.tickets;
          if(this.estatusFiltro != 8){
             newtickets = newtickets.filter(el => el.estatus == this.estatusFiltro)
          }
          if(this.urgente){
            newtickets = newtickets.filter(el => el.urgente == this.urgente)
          }
          return newtickets
        }
      },
    },

    methods :{
      initialize () {
        this.getTickets()
      },

      closeModal(){
        this.dialogHistorial = false
      },

      itemRowBackground: function (item) {
        return item.urgente ? 'style-1' : ''
      },

      getTickets (){
        this.cargar = true
        if(this.getdatosUsuario.idpuesto == 18){ //puesto de las encargadas
          const planteles = this.getdatosUsuario.planteles.map((registro)=>{ return registro.idplantel })
          console.log(planteles)
          this.$http.post('tickets.encargadas/', planteles).then(response=>{
            for(const i in response.data){
              response.data[i].fecha_apertura = moment(response.data[i].fecha_apertura).format('ll, h:mm a');
              this.tickets.push(response.data[i])

              this.tickets.forEach(element => {
                if(element.motivo.length > 500){
                  element.motivocorto = element.motivo.substring(0, 500) + "..."
                } else {
                  element.motivocorto = element.motivo
                }
              });
            }
          }).catch(error=>{
            console.log(error)
          }).finally( () => { this.cargar = false })
        } else {
          this.cargar = false
        }
        console.log(this.tickets)
      },

      verMotivo(value){
        this.cargar = true
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.dialogHistorial = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },
    }
  }
</script>

<style>
  .style-1 {
    background-color: rgb(253, 169, 169);
  }
  .style-1:hover {
    background-color: rgb(238, 131, 131) !important;
  }
</style>

